<template>
    <div>
        <h2>ช่องทางการชำระ > ตั้งค่าการชำระเงินผ่านบัตรเครดิต/QR</h2>

        <div>
          <v-btn-toggle
            v-model="selectedPage"
            tile
            color="primary"
            group
          >
            <v-btn value="bank" @click="$router.push({ name: 'payment' })">
              โอนผ่านบัญชีธนาคาร
            </v-btn>

            <v-btn value="beam" @click="$router.push({ name: 'payment_beam' })">
              บัตรเครดิต/ผ่อน/QR
            </v-btn>            
          </v-btn-toggle>
        </div>  

        <v-form v-model="isGBFormValid">
          <v-card class="mt-4">            
            <v-card-title>ตั้งค่าการชำระเงินผ่านบัตรเครดิตด้วย Beam Checkout</v-card-title>
            <v-card-subtitle>ยังไม่มีบัญชีกับ Beam Checkout ? เปิดบัญชีใหม่</v-card-subtitle>
            
            <div class="pa-6">
              <v-row>
                <v-col cols="12" md="6">
                    <v-row>
                        <v-col>
                            <v-text-field
                                v-model="gbFormData.beam_merchant_id"
                                outlined
                                label="Merchant ID *"                               
                                :rules="[validators.required]"
                                hide-details="auto"          
                                dense  
                            ></v-text-field>
                        </v-col>
                    </v-row>   
                    <v-row>
                        <v-col>
                            <v-text-field
                                v-model="gbFormData.beam_merchant_key"
                                outlined
                                label="Merchant Key *"                               
                                :rules="[validators.required]"
                                hide-details="auto"          
                                dense  
                                type="password"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-text-field
                                v-model="gbFormData.beam_secret_key"
                                outlined
                                label="Secret Key *"                               
                                :rules="[validators.required]"
                                hide-details="auto"          
                                dense  
                                type="password"
                            ></v-text-field>
                        </v-col>
                    </v-row> 
                </v-col>
                <v-col cols="12" md="6">
                    <!-- VISA INFO -->
                    <v-img src="@/bulitInImages/paymentcard.svg"></v-img>
                </v-col>
              </v-row>
            </div>

            <v-card-actions>              
              <v-btn
                color="primary"               
                large          
                :disabled="!isGBFormValid"
                :loading="sendingData"
                @click="submitKey"
              >
                แก้ไข
              </v-btn>

              <v-btn
                color="secondary"               
                outlined                 
                large
                :disabled="sendingData"
                @click="$router.go(-1)"
              >
                กลับ
              </v-btn>                
            </v-card-actions>
          </v-card>   
        </v-form>

        <v-form v-model="isCreditFormValid">
          <v-card class="mt-4">            
            <v-card-title>
              <v-switch
                v-model="creditFormData.is_credit_allowed"                
              ></v-switch>
              <label for="">บัตรเครดิต/เดบิต</label>
            </v-card-title>
            <v-card-subtitle style="margin-top: -35px;">ตั้งค่าเฉพาะ การจ่ายเงินด้วบบัตรเครดิต</v-card-subtitle>
            
            <div class="px-6 py-2" v-if="creditFormData.is_credit_allowed">
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field
                      v-model.number="creditFormData.beam_credit_min"
                      outlined
                      label="ยอดสั่งซื้อขั้นต่ำ *"                               
                      :rules="[validators.required, validators.minValueValidator(creditFormData.beam_credit_min, 0)]"                                
                      dense
                      hint="ต้องสั่งซื้อเกินยอดที่กำหนด ถึงจะชำระผ่านทางช่องทางนี้ได้; 0 คือ ไม่มีขั้นต่ำ"
                      persistent-hint
                  ></v-text-field>
                </v-col>                
              </v-row>              
              <v-row no-gutters>
                <v-col cols="12" md="6" class="d-flex align-center">
                  <div class="black--text">
                    ผู้รับผิดชอบค่าธรรมเนียม
                  </div>                  
                  <v-radio-group
                    v-model="creditFormData.beam_credit_fee_absorption"
                    row
                    class="ml-2"
                  >
                    <v-radio
                      label="เรา"
                      :value="true"
                    ></v-radio>
                    <v-radio
                      label="ลูกค้า"
                      :value="false"
                    ></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
              <v-row v-if="!creditFormData.beam_credit_fee_absorption">
                <v-col cols="12" md="6">
                  <v-text-field
                      v-model.number="creditFormData.beam_credit_fee_rate"
                      outlined
                      label="ค่าธรรมเนียม (%) *"                               
                      :rules="[
                        validators.required, 
                        validators.minValueValidator(creditFormData.beam_credit_fee_rate, 0),
                        validators.maxValueValidator(creditFormData.beam_credit_fee_rate, 99.99)
                      ]"                                
                      dense
                      persistent-hint
                  ></v-text-field>
                </v-col>                
              </v-row>
            </div>

            <v-card-actions>              
              <v-btn
                color="primary"               
                large          
                :disabled="!isCreditFormValid"
                :loading="sendingData"
                @click="submitCredit"                
              >
                แก้ไข
              </v-btn>

              <v-btn
                color="secondary"               
                outlined                 
                large
                :disabled="sendingData"
                @click="$router.go(-1)"
              >
                กลับ
              </v-btn>                
            </v-card-actions>
          </v-card>   
        </v-form>

    </div>
</template>
<script>
import { maxLengthValidator, maxValueValidator, minValueValidator, required } from '@/@core/utils/validation'
import { asyncGet, asyncUpdate, asyncUpdateAsJson } from '@/helpers/asyncAxios'
export default {
  data() {
    return {
      selectedPage: 'beam',
      sendingData: false,
      isGBFormValid: false,
      gbFormData: {
        beam_merchant_id: null,
        beam_merchant_key: null,
        beam_secret_key: null,
      },
      isCreditFormValid: false,
      creditFormData: {
        is_credit_allowed: false,
        beam_credit_min: 0,
        beam_credit_fee_rate: 0,
        beam_credit_fee_absorption: true,
      },
      validators: {
        maxLengthValidator,
        required,
        minValueValidator,
        maxValueValidator,
      },
    }
  },
  async created() {
    this.$store.commit('app/SET_GLOBAL_LOADING', true)
    try {
      const {
        beam_credit_fee_absorption,
        beam_credit_fee_rate,
        beam_credit_min,
        beam_merchant_id,
        beam_merchant_key,
        beam_secret_key,
        banks,
        is_credit_allowed,
      } = await asyncGet('/setting/payment/beam')

      this.gbFormData = {
        beam_merchant_id: beam_merchant_id,
        beam_merchant_key: beam_merchant_key,
        beam_secret_key: beam_secret_key
      }

      this.creditFormData = {
        is_credit_allowed: !!is_credit_allowed,
        beam_credit_min: beam_credit_min,
        beam_credit_fee_rate: beam_credit_fee_rate,
        beam_credit_fee_absorption: !!beam_credit_fee_absorption,
      }
    } catch (error) {
      this.$root.showCommonDialog('มีปัญหา', error)
    }
    this.$store.commit('app/SET_GLOBAL_LOADING', false)
  },
  methods: {
    async submitKey() {
      this.sendingData = true
      try {
        await asyncUpdate('/setting/payment/beam/key', this.gbFormData)
      } catch (error) {
        this.$root.showCommonDialog('มีปัญหา', error)
      }

      this.sendingData = false
    },
    async submitCredit() {
      this.sendingData = true
      try {
        await asyncUpdate('/setting/payment/beam/credit', this.creditFormData)
      } catch (error) {
        this.$root.showCommonDialog('มีปัญหา', error)
      }

      this.sendingData = false
    },    
  },
}
</script>